<template>
  <div class="box-wrap">
    <div class="header-box"></div>
    <div class="form-box">
      <div class="form-box-css">
        <MindForm
          activityId="463650517583872002"
          title="预约顾问咨询"
          btnTitle="立即预约"
        />
      </div>
    </div>
    <a class="phone-box" href="tel:4008122133">
      <span class="icon"></span>
      <p>咨询热线: <span>4008-122-133</span></p></a
    >
  </div>
</template>
<script>
import { collectPointApi } from "@/api/apiList.js";
import { setChannel } from "@/util/setChannel.js";
import { mapGetters } from "vuex";
export default {
  namae: "middleTwo",
  components: {
    MindForm: () => import("@/views/middle/form.vue"),
  },
  created() {
    setChannel(this);
    setTimeout(() => {
      this.buryingPage(6);
    }, 1000);
  },
  computed: {
    ...mapGetters(["pages", "UUID"]),
  },
  methods: {
    // 埋点
    buryingPage(type) {
      let collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: "463650517583872002",
        eventType: type,
        uuid: this.UUID,
        localUrl: location.href,
      };
      collectPointApi(collectPointParams)
        .then((res) => {
          console.log(res, "埋点成功");
        })
        .catch((err) => {
          console.log(err, "买点失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.box-wrap {
  background: linear-gradient(180deg, #fff4df 0%, #fffcf7 100%);
  .header-box {
    width: 100%;
    height: 2.54rem;
    background: url("~@/assets/img/middle/banner-3.png") no-repeat center;
    background-size: 100%;
  }
  .form-box {
    padding: 0 0.24rem;
    .form-box-css {
      background: #fff;
      padding: 0.8rem 0.24rem;
    }
  }
  .phone-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.24rem;
    color: #999999;
    font-size: 0.26rem;
    font-weight: 400;
    p {
      span {
        font-size: 0.28rem;
      }
    }
    .icon {
      width: 0.28rem;
      height: 0.28rem;
      background: url("~@/assets/img/middle/phone-icon.png") no-repeat center;
      background-size: 100%;
      margin-right: 0.08rem;
    }
  }
}
</style>